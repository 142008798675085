<template>
  <div>
    <!-- ANCHOR: Filters -->
    <PromotionAgFilters
      :type-filter.sync="typeFilter"
      :start-date.sync="startDate"
      :end-date.sync="endDate"
      :airline.sync="airline"
      :name.sync="name"
      :code.sync="code"
      @fetch-data="refetchData"
      @reset="clearFilter"
    />

    <!-- SECTION: Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <!-- SECTION Table Top -->
      <div class="mx-2 my-1">
        <b-row
          align-v="center"
          class="justify-content-md-between flex-md-nowrap"
        >
          <!-- ANCHOR size per page -->
          <b-col
            cols="4"
            md="auto"
            class="px-0 mr-md-1"
          >
            <v-select
              v-model="sizePerPage"
              :options="sizePerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
              <span class="text-muted ml-50">{{ $t('promotionAg.promotions') }}</span>
            </v-select>
          </b-col>

          <b-col
            cols="8"
            md="auto"
            class="d-flex justify-content-end px-50"
            order-md="3"
          >
            <div class="">
              <!-- ANCHOR button create -->
              <!-- :disabled="!canAccess('employee.createEmployee')" -->
              <b-button
                v-if="canCreate"
                class="px-lg-1 px-sm-75"
                variant="info"
                @click="() => $router.push({name: 'apps-promotionAg-create'})"
              >
                <span class="text-nowrap">
                  <feather-icon
                    class="cursor-pointer"
                    icon="PlusSquareIcon"
                    size="16"
                  />
                  <span class="d-none d-sm-inline ml-50">{{ $t('promotionAg.Create') }}</span>
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- !SECTION -->

      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <!-- SECTION Table Content -->
        <b-table
          ref="refPromotionsListTable"
          style="max-height: 70vh"
          :sticky-header="true"
          class="position-relative"
          :items="fetchPromotionsByFilter"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('noMatchingResult')"
          :sort-desc.sync="isSortDirDesc"
          :sort-by.sync="sortBy"
        >
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]="data"
          >
            <span
              :key="column.label"
              class="text-dark text-nowrap"
            >
              {{ $t(`promotionAg.tableTitle.${data.label}`) }}
            </span>
          </template>

          <template #cell(no)="data">
            <div class="text-nowrap">
              <span class="font-weight-bold">
                {{ ((currentPage - 1) * sizePerPage) + data.index + 1 }}
              </span>
            </div>
          </template>

          <template #cell(code)="data">
            <div class="text-nowrap">
              <b-link
                :disabled="!canAccess('employee.detailEmployee')"
                :to="{
                  name: 'apps-promotionAg-edit',
                  params: { id: data.item.id },
                }"
                class="font-weight-bold d-inline text-nowrap text-info"
              >
                <span
                  class="align-text-top text-capitalize font-weight-bold"
                  style="font-family: monospace;"
                >
                  {{ data.item.code }}
                </span>
              </b-link>
              <div
                class="text-nowrap"
                style="max-width: 120px;"
              >
                <div
                  v-b-tooltip.hover.v-primary="data.item.name"
                  class="text-truncate"
                >
                  {{ data.item.name }}
                </div>
              </div>
            </div>
          </template>

          <template #cell(name)="data">
            <div
              class="text-nowrap"
              style="max-width: 120px;"
            >
              <div
                v-b-tooltip.hover.v-primary="data.item.name"
                class="text-truncate"
              >
                {{ data.item.name }}
              </div>
            </div>
          </template>

          <template #cell(paidType)="data">
            {{ $t(`promotionAg.${data.item.paidType}`) }}
          </template>

          <template #cell(minimumPassenger)="data">
            {{ data.item.minimumPassenger }}
          </template>

          <template #cell(agencies)="data">
            <div
              v-b-tooltip.hover.v-primary="data.item.agencies ? data.item.agencies.join(', ') : ''"
              class="text-nowrap text-truncate"
              style="max-width: 200px;"
            >
              {{ data.item.agencies ? data.item.agencies.join(', ') : '' }}
            </div>
          </template>

          <template #cell(flightTrips)="data">
            <div
              v-b-tooltip.hover.v-primary="data.item.flightTrips ? data.item.flightTrips.join(', ') : ''"
              class="text-nowrap text-truncate"
              style="max-width: 200px;"
            >
              {{ data.item.flightTrips ? data.item.flightTrips.join(', ') : '' }}
            </div>
          </template>
          <template #cell(flightTypes)="data">
            <div class="text-nowrap">
              {{ data.item.flightType }}
            </div>
          </template>

          <template #cell(discount)="data">
            <div class="text-right text-nowrap">
              {{ data.item.discountAmount ? formatCurrency(data.item.discountAmount) : data.item.discountPercent }} {{ data.item.discountPercent > 0 ? '%' : 'VND' }}
            </div>
          </template>

          <template #cell(flightStartTime)="data">
            <div class="text-nowrap">
              <b>BĐ:</b> {{ data.item.flightStartTime && convertISODateTime(data.item.flightStartTime.substr(0, 10)).date }}
            </div>
            <div class="text-nowrap">
              <b>KT:</b> {{ data.item.flightEndTime && convertISODateTime(data.item.flightEndTime.substr(0, 10)).date }}
            </div>
          </template>

          <template #cell(startTime)="data">
            <div class="text-nowrap">
              <b>BĐ:</b> {{ data.item.startTime && convertISODateTime(data.item.startTime.substr(0, 10)).date }}
            </div>
            <div class="text-nowrap">
              <b>KT:</b> {{ data.item.endTime && convertISODateTime(data.item.endTime.substr(0, 10)).date }}
            </div>
          </template>

          <template #cell(quota)="data">
            <div class="text-nowrap">
              <b>Số tiền:</b> {{ data.item.appliedAmount }} / {{ data.item.quotaAmount }}
            </div>
            <div class="text-nowrap">
              <b>Số phiếu:</b> {{ data.item.appliedCounter }} / {{ data.item.quotaCounter }}
            </div>
          </template>

          <template #cell(active)="data">
            <b-form-checkbox
              v-model="data.item.active"
              class="mr-0 mt-50 custom-control-success"
              name="check-button"
              switch
              inline
              :disabled="!canCreate"
              @click.native.prevent="canCreate && changeActive(data.item)"
            />
          </template>

          <template #cell(action)="data">
            <b-button
              v-if="canEdit(data.item)"
              variant="flat-danger"
              :disabled="!canAccess('customer.deleteCustomer')"
              class="px-1"
              @click="confirmDelete(data.item.id)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
                style="cursor: pointer"
              />
            </b-button>
          </template>

          <template #cell(createdAt)="data">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-info"
                  size="34"
                >
                  <feather-icon
                    icon="ClockIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0 text-nowrap">
                  {{ dateTime(data.item.createdAt) }}
                </h6>
                <small class="text-nowrap">
                  <span v-b-tooltip.hover.v-primary="$t('Username')">{{ data.item.createdBy || '' }}</span>
                  (<span v-b-tooltip.hover.v-primary="$t('Agency')">{{ data.item.createdAgency || '' }}</span>)
                </small>
              </b-media-body>
            </b-media>
          </template>

          <template #cell(updatedAt)="data">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-info"
                  size="34"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0 text-nowrap">
                  {{ dateTime(data.item.updatedAt) }}
                </h6>
                <small class="text-nowrap">
                  {{ data.item.updatedBy || '' }}
                </small>
              </b-media-body>
            </b-media>
          </template>
        </b-table>
        <!-- !SECTION -->
      </b-overlay>

      <!-- Table Footer -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Showing -->
          <b-col
            cols="12"
            sm="2"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-nowrap">{{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }}
              <span class="d-inline-block d-sm-none d-md-inline"> {{ $t('paginationText.outOf') }} </span>
              <span class="d-none d-sm-inline d-md-none"> / </span>
              {{ dataMeta.of }} {{ $t('paginationText.items') }}</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="8"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPromotions"
              :per-page="sizePerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BPagination, BMediaBody, BMediaAside, BFormCheckbox,
  BOverlay,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  computed,
  onUnmounted,
} from '@vue/composition-api'
import moment from 'moment'

import store from '@/store'
import { sizePerPageOptions } from '@/constants/selectOptions'

import {
  avatarText, dateTime, convertISODateTime, formatCurrency,
} from '@core/utils/filter'

import promotionAgStoreModule from '@promotionAg/promotionAgStoreModule'
import usePromotionAgHandle from '@promotionAg/usePromotionAgHandle'

import PromotionAgFilters from './filters.vue'

export default {
  components: {
    PromotionAgFilters,
    BMediaBody,
    BMediaAside,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BOverlay,

    vSelect,
  },
  directives: { 'b-tooltip': VBTooltip },
  setup() {
    const PROMOTION_AG_APP_STORE_MODULE_NAME = 'app-promotionAg'

    // Register module
    if (!store.hasModule(PROMOTION_AG_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROMOTION_AG_APP_STORE_MODULE_NAME, promotionAgStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROMOTION_AG_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROMOTION_AG_APP_STORE_MODULE_NAME)
      }
    })

    const {
      fetchPromotionsByFilter,
      tableColumns,
      sizePerPage,
      currentPage,
      totalPromotions,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refPromotionsListTable,
      refetchData,
      clearFilter,
      updateActive,
      deletePromotion,

      // Extra Filters
      typeFilter,
      startDate,
      endDate,
      airline,
      name,
      code,

      genderFilter,
      searchTextFilter,
      statusFilter,
      createdByFilter,

      // Loading
      loading,

      isEmptyFilter,

    } = usePromotionAgHandle()

    function changeActive(promotionAg) {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatus'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            updateActive(promotionAg.id, !promotionAg.active)
          }
        })
    }

    function confirmDelete(id) {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('promotionAg.delete.confirm') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            deletePromotion(id)
          }
        })
    }

    const meData = computed(() => store.getters['userStore/getMeData'])
    const canCreate = computed(() => ['OPE', 'ADM'].includes(meData.value?.type))
    const canEdit = promotion => {
      const isType = ['OPE', 'ADM'].includes(meData.value?.type)
      const isStarted = moment().isAfter(moment(promotion.startTime))
      return isType && !isStarted
    }

    return {
      // Sidebar
      changeActive,
      updateActive,
      fetchPromotionsByFilter,
      deletePromotion,

      confirmDelete,
      dateTime,
      convertISODateTime,
      tableColumns,
      totalPromotions,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refPromotionsListTable,
      refetchData,
      clearFilter,

      // Params
      sizePerPage, // to API: size
      currentPage, // to API: page

      // Filter
      avatarText,

      // select Options
      sizePerPageOptions,

      // Extra Filters
      typeFilter,
      startDate,
      endDate,
      airline,
      name,
      code,

      genderFilter,
      searchTextFilter,
      statusFilter,
      createdByFilter,

      // Loading
      loading,

      // Empty filter
      isEmptyFilter,
      formatCurrency,

      // access
      canCreate,
      canEdit,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector ::v-deep {
  width: 100%;
  max-width: 90px;
  .vs__selected-options {
    flex-wrap: nowrap
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
